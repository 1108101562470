<template>
  <v-row dense>
    <v-col cols="12">
      <v-textarea
        v-model="observation"
        label="Nova Observação"
        outlined
        rounded
        dense
        auto-grow
      />
      <v-btn
        color="primary"
        width="100%"
        @click="addObservation"
        rounded
        outlined
        :disabled="!observation"
      >
        Adicionar Observação
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card
        flat
        v-if="historic.length > 0"
        class="pa-5"
      >
        <h6 class="overline secondary--text">Histórico</h6>
        <div
          v-for="(obs, idx) in crmHistoric"
          :key="idx"
        >
          <div class="d-flex justify-space-between align-center">
            <h6 class="text-h6 primary--text">
              {{ obs.created_at | convertDate }}
            </h6>
            <v-btn
              v-if="!CRM_ID"
              @click="$emit('removeObservation', idx)"
              icon
              color="error"
            >
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </div>
          <p class="body-2">{{ obs.observation }}</p>
          <v-divider class="my-2" />
        </div>
      </v-card>
      <div v-else>
        <h6 class="text-center text-h5">Nenhuma observação adicionada</h6>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    crmHistoric: {
      type: Array,
      default: () => [],
    },
    CRM_ID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      observation: null,
      historic: [],
    }
  },
  watch: {
    crmHistoric: {
      handler(newvalue) {
        this.historic = newvalue
      },
      immediate: true,
      deep: true,
    },
  },
  filters: {
    convertDate(value) {
      return value.toLocaleString('pt-BR').replace(', ', ' - ')
    },
  },
  methods: {
    addObservation() {
      let date = new Date(Date.now())
      var obervationObj = {
        observation: this.observation,
        created_at: date,
      }

      this.$emit('setHistoric', obervationObj)
      this.observation = null
    },
  },
}
</script>

<style>
</style>